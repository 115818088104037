@use '@angular/material' as mat;
@use './mixins.scss' as mixins;
@use './global_vars' as global;
@import '~@angular/material/theming';

@import 'variables.scss';

@include mat.core($custom-typography);

@include mat.all-component-themes(
    $my-theme
); // the declaration of our custom material theme

html {
    -webkit-text-size-adjust: 100%;
    font: -apple-system-body;
}

html,
body material-app,
mat-sidenav-container {
    height: 100%;
}

body {
    margin: 0;
}

.mat-checkbox-layout {
    white-space: normal !important;
}

.mat-radio-label-content {
    white-space: normal !important;
}

//Stops the Flash of unstyled text
.mat-icon,
mat-icon {
    font-family: 'Material Icons' !important;
}

//Fix mat-button with mat-icon alignment
.mat-icon-button .mat-button-wrapper {
    display: flex;
    justify-content: center;
}

.valign-center {
    display: inline-flex !important;
    vertical-align: middle;
    align-items: center;
}

.fullButton {
    width: 90%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

    .mat-button-wrapper {
        width: 100%;
        text-align: center;
    }
}

.spacing {
    flex: 1 1 auto;
}

.holder {
    padding: 10px;
    background-color: mat.get-color-from-palette($background);
    margin-bottom: 130px;
}

.desc {
    font-weight: lighter;
}

.price {
    font-weight: bold;
    font-style: oblique;
    color: mat.get-color-from-palette($accent);
}

.menuitem {
    font-size: larger;
    margin-bottom: 5px;
}

.clickable {
    cursor: pointer;
}

.amount {
    text-align: right;
}

.option {
    font-size: small;
}

.total {
    margin-top: 10px;
    font-weight: bold;
    font-size: larger;
}

.foodpreview {
    width: 100%;
    object-fit: contain;
}

.backBottom {
    width: 90px;
    margin-right: 10px;
}

@mixin titlemixin {
    margin: 5px;
    font-weight: normal;
    font-variant-caps: all-small-caps;
}

.title {
    @include titlemixin;
    font-size: xx-large;
    margin-bottom: 15px;
}

.titleBase {
    @include titlemixin;
    font-size: x-large;
}

.floatPanel {
    position: fixed;
    width: 100vw;
    bottom: 0;
    z-index: 901;
}

.errormsg {
    margin: 5px;
    font-size: x-large;
    text-align: center;
    color: mat.get-color-from-palette($warn);
}

.spacer {
    flex: 1 1 auto;
}

//.spacer {
//  flex: 1 1 100%
//}

button.smallButtonWithIcon {
    height: 24px;

    mat-icon {
        height: 24px;
    }

    :host-context {
        height: 24px;
    }
}

td {
    padding: 0px;
}

.custom-dialog {
    max-width: 100vw !important;
    max-height: 90vh;
    padding-bottom: 1rem;
    // overflow: hidden;
    @include mixins.small {
        max-width: 80vw;
    }
    .mat-dialog-container {
        margin: 0;
        max-width: 100vw !important;
        padding: 15px 5px !important;
        overflow: hidden !important;
        @include mixins.small {
            margin: auto;
            max-width: 80vw;
            padding: 24px !important;
        }
    }
}

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    pointer-events: {
        width: 100vw !important;
    }
}

.success-snackbar {
    background-color: rgb(35, 156, 35);
    color: white;
}

.failure-snackbar {
    background-color: global.$failure;
    color: black;
}
.select .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    max-width: 8.125rem;
    @include mixins.small {
        max-width: 8.75rem;
    }

    @include mixins.small-med {
        max-width: 9.6875rem;
    }
    @include mixins.medium {
        max-width: auto;
    }
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
}

.mat-button-wrapper {
    // display: inline-block !important;
}

.mat-form-field-outline {
    background-color: #212020;
    border-radius: 5px;
}

.mat-drawer-container {
    overflow: visible !important;
}

.mat-drawer-content {
    overflow: visible !important;
}

.transaction-dialog {
    max-width: 100vw !important;

    padding-bottom: 1rem;

    @include mixins.small {
        max-width: 80vw;
    }
    .mat-dialog-container {
        margin: 0;
        max-width: 100vw !important;
        padding: 0.9375rem 0.3125rem !important;
        @include mixins.small {
            margin: auto;
            max-width: 80vw;
            padding: 1.5rem !important;
        }
    }
}

.dark-snackbar {
    background-color: mat.get-color-from-palette($primary, 900);
    color: mat.get-color-from-palette($primary, 100);
}

.blur {
    backdrop-filter: blur(4px);
}
