@use '@angular/material' as mat;
@use 'variables.scss' as var;

@mixin xsmall {
    @media screen and (min-width: 20rem) {
        @content;
    }
}

@mixin small {
    @media screen and (min-width: 21.875rem) {
        @content;
    }
}

@mixin small-med {
    @media screen and (min-width: 25rem) {
        @content;
    }
}

@mixin medium {
    @media screen and (min-width: 45rem) {
        @content;
    }
}

@mixin large {
    @media screen and (min-width: 75rem) {
        @content;
    }
}

@mixin short {
    @media screen and (max-height: 40.625rem) {
        @content;
    }
}

@mixin cta-button {
    width: 100%;
    background-color: mat.get-color-from-palette(var.$accent);
    border-radius: 5px;
    color: mat.get-color-from-palette(var.$primary, 100);
    padding: 1rem;
    border: none;
    cursor: pointer;
    transition-duration: 0.3s;
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 5px 5px 15px -4px #000000;
    box-shadow: 5px 5px 15px -4px #000000;
    text-transform: uppercase;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
